import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Pricing from './components/Pricing'; 
import { UserContextProvider } from './UserContext';
import Testimonial from './components/Testimonials';
import ToBeFreelancer from './components/ToBeFreelancer';
import Bill from './components/Bill';
import Bill1 from './components/Bill1';
import VenueDate from './components/venueDate';
import EditInvoice from './components/editInvoice';
import Addtocart from './components/AddToCart';
import "bootstrap/dist/css/bootstrap.min.css"; 
import PricingHaldi from './components/PricingHaldi';
import PricingPreWedding from './components/PricingPreWedding';
import PricingWedding from './components/PricingWedding';
import PricingMehandi from './components/PricingMehandi';
import PricingSangeet from './components/PricingSangeet';
import PricingEngagement from './components/PricingEngagement';
import PricingReception from './components/PricingReception';
import PricingPreBirthday from './components/PricingPreBirthday';
import PricingBirthday from './components/PricingBirthday';
import PricingNewBorn from './components/PricingNewBorn';
import PricingBaby from './components/PricingBaby';
import PricingMaternity from './components/PricingMaternity';
import PricingAnniversary from './components/PricingAnniversary';
import PricingCorporate from './components/PricingCorporate';
import PricingPortfolio from './components/PricingPortfolio';
import PricingProduct from './components/PricingProduct';
import Login from './components/Login';
import Signup from './components/Register';
import Home from './components/Home';
import ServicePage from './components/services/ServicePage';
import CityWisePricing from './components/services/CityWisePricing';
import Test from './Test';
import ContactPage from './components/Contact';
import Portfolio from './components/Portfolio';
import Testadmin from './admin-dashboard/AdminDashboard';
import PaymentGateway from './components/Payment-Gateway';
import AboutC from './components/AboutC';
import Sitemap from './Sitemap';

const Franchisee = () => {
  return (
    <div style={{ width: "100vw", height: "80vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <h1>Coming Soon</h1>
    </div>
  );
}


const App = () => {
  return (
    <UserContextProvider>
      <Router>
      <Header />
      <Routes>
        <Route path="/home" component={<Home/>} />
        <Route path="/" element={<Portfolio/>} />
        {/* <Route path="/services" element={<ServicePage/>} /> */}
        {/* <Route path="/services/:serviceName" element={<ServicePage/>}/> */}
        <Route path="/services/Pre-Wedding-Photographers" element={<ServicePage serv={1}/>}/>
        <Route path="/services/Wedding-Photographers" element={<ServicePage serv={2}/>}/>
        <Route path="/services/Haldi-Photographers" element={<ServicePage serv={3}/>}/>
        <Route path="/services/Mehandi-Photographers" element={<ServicePage serv={4}/>}/>
        <Route path="/services/Sangeet-Photographers" element={<ServicePage serv={5}/>}/>
        <Route path="/services/Engagement-Photographers" element={<ServicePage serv={6}/>}/>
        <Route path="/services/Reception-Photographers" element={<ServicePage serv={7}/>}/>
        <Route path="/services/Pre-Birthday-Party-Photographers" element={<ServicePage serv={8}/>}/>
        <Route path="/services/Birthday-Shoot-Photographers" element={<ServicePage serv={9}/>}/>
        <Route path="/services/New-Born-Baby-Photographers" element={<ServicePage serv={10}/>}/>
        <Route path="/services/Baby-Shoot-Photographers" element={<ServicePage serv={11}/>}/>
        <Route path="/services/Maternity-Photographers" element={<ServicePage serv={12}/>}/>
        <Route path="/services/Wedding-Anniversary-Photographers" element={<ServicePage serv={13}/>}/>
        <Route path="/services/Corporate-Event-Photographers" element={<ServicePage serv={14}/>}/>
        <Route path="/services/Portfolio-Photographers" element={<ServicePage serv={15}/>}/>
        <Route path="/services/Product-Photographers" element={<ServicePage serv={16}/>}/>
        <Route path="/services/:serviceType-Photographers/:cityName" element={<CityWisePricing/>}/>
        <Route path="/services" element={<Pricing/>} />
        <Route path='/services/Pre-Wedding-Photographers/:cityName/:pricing' element={<PricingPreWedding/>} />
        <Route path='/services/Wedding-Photographers/:cityName/:pricing' element={<PricingWedding/>} />
        <Route path='/services/Haldi-Photographers/:cityName/:pricing' element={<PricingHaldi/>} />
        <Route path='/services/Mehandi-Photographers/:cityName/:pricing' element={<PricingMehandi/>} />
        <Route path='/services/Sangeet-Photographers/:cityName/:pricing' element={<PricingSangeet/>} />
        <Route path='/services/Engagement-Photographers/:cityName/:pricing' element={<PricingEngagement/>} />
        <Route path='/services/Reception-Photographers/:cityName/:pricing' element={<PricingReception/>} />
        <Route path='/services/Pre-Birthday-Party-Photographers/:cityName/:pricing' element={<PricingPreBirthday/>} />
        <Route path='/services/Birthday-Shoot-Photographers/:cityName/:pricing' element={<PricingBirthday/>} />
        <Route path='/services/New-Born-Baby-Photographers/:cityName/:pricing' element={<PricingNewBorn/>} />
        <Route path='/services/Baby-Shoot-Photographers/:cityName/:pricing' element={<PricingBaby/>} />
        <Route path='/services/Maternity-Photographers/:cityName/:pricing' element={<PricingMaternity/>} />
        <Route path='/services/Wedding-Anniversary-Photographers/:cityName/:pricing' element={<PricingAnniversary/>} />
        <Route path='/services/Corporate-Event-Photographers/:cityName/:pricing' element={<PricingCorporate/>} />
        <Route path='/services/Portfolio-Photographers/:cityName/:pricing' element={<PricingPortfolio/>} />
        <Route path='/services/Product-Photographers/:cityName/:pricing' element={<PricingProduct/>} />

        <Route path='/login' element={<Login/>}/>
        <Route path="/testimonials" element={<Testimonial/>} />
        <Route path="/contact" element={<ContactPage/>} />
        <Route path='/tojoinus' element={<ToBeFreelancer/>}/>
        <Route path='/bill' element={<Bill/>}/>
        <Route path='/bill1' element={<Bill1/>}/>
        <Route path='/venuedate' element={<VenueDate/>}/>
        <Route path='/register' element={<Signup/>}/>
        <Route path='/editinvoice' element={<EditInvoice/>}/>
        {/* <Route path='/cart' element={<Addtocart/>}/> */}
        {/* <Route path='/test' element={<Test/>}/> */}
        <Route path='/forfranchisee' element={<Franchisee/>}/>
        <Route path='/admin' element={<Testadmin/>}/>
        <Route path='/payment' element={<PaymentGateway/>}/>
        <Route path='/aboutus' element={<AboutC/>}/>
        <Route path='/sitemap' element={<Sitemap/>}/>
      </Routes>
    </Router>
    </UserContextProvider>
  );
};

export default App;
