import React from "react";
import { useEffect,useState,useContext} from 'react';
import { UserContext } from "../UserContext";
import {Link, } from 'react-router-dom';
import "./Pricing.css";
import Footer from "./Footer";

const Pricing = () => {
  const {setUserInfo,userInfo} = useContext(UserContext);
  useEffect(() => {
    fetch('/profile', {
      credentials: 'include',
    }).then(response => {
      response.json().then(userInfo => {
        setUserInfo(userInfo);
      });
    });
  },[] );

    const services = [
    {
      title1: "Pre Wedding",
      title: "Pre-Wedding-Photographers",
      discount: "upto 50% off",
      imageUrl: "images/pricingprewedding.jpg",
      route:'/pricingprewedding',
    },
    {
      title1: "Wedding",
      title: "Wedding-Photographers",
      discount: "upto 60% off",
      imageUrl: "images/pricingwedding.jpg",
      route:'/pricingwedding',
    },
    {
      title1: "Haldi",
      title: "Haldi-Photographers",
      discount: "upto 20% off",
      imageUrl: "images/pricinghaldi.jpg",
      route:'/pricinghaldi',
    },
    {
      title1: "Mehandi",
      title: "Mehandi-Photographers",
      discount: "upto 20% off",
      imageUrl: "images/pricingmehandi.jpg",
      route:'/pricingmehandi',
    },
    {
      title1: "Sangeet",
      title: "Sangeet-Photographers",
      discount: "upto 20% off",
      imageUrl: "images/pricingsangeetver.jpg",
      route:'/pricingsangeet',
    },
    {
      title1: "Engagement",
      title: "Engagement-Photographers",
      discount: "upto 30% off",
      imageUrl: "images/pricingengagement.jpg",
      route:'/pricingengagement',
    },
    
    {
      title1: "Reception",
      title: "Reception-Photographers",
      discount: "upto 25% off",
      imageUrl: "/images/pricingreception.jpg",
      route:'/pricingreception',
    },
    {
      title1: "Pre Birthday Party",
      title: "Pre-Birthday-Party-Photographers",
      discount: "upto 50% off",
      imageUrl: "freelance.webp",
      route:'/pricingprebirthday',
    },
    {
      title1: "Birthday Shoot",
      title: "Birthday-Shoot-Photographers",
      discount: "upto 50% off",
      imageUrl: "/images/pricingbirthdayser.jpg",
      route:'/pricingbirthday',
    },
    {
      title1: "New Born Baby",
      title: "New-Born-Baby-Photographers",
      discount: "upto 50% off",
      imageUrl: "/images/pricingnewbornbaby.jpg",
      route:'/pricingnewbornbaby',
    },
    {
      title1: "Baby Shoot",
      title: "Baby-Shoot-Photographers",
      discount: "upto 50% off",
      imageUrl: "/images/pricingbaby.jpg",
      route:'/pricingbaby',
    },
    {
      title1: "Maternity",
      title: "Maternity-Photographers",
      discount: "upto 50% off",
      imageUrl: "/images/pricingmaternity.jpg",
      route:'/pricingmaternity',
    },
    {
      title1: "Wedding Anniversary",
      title: "Wedding-Anniversary-Photographers",
      discount: "upto 50% off",
      imageUrl: "/images/pricinganniversary.jpg",
      route:'/pricinganniversary',
    },
    {
      title1: "Corporate Event",
      title: "Corporate-Event-Photographers",
      discount: "upto 50% off",
      imageUrl: "/images/pricingcorporate.jpg",
      route:'/pricingcorporate',
    },
    {
      title1: "Portfolio",
      title: "Portfolio-Photographers",
      discount: "upto 50% off",
      imageUrl: "/images/pricingportfolioser.jpg",
      route:'/pricingportfolio',
    },
    {
      title1: "Product",
      title: "Product-Photographers",
      discount: "upto 50% off",
      imageUrl: "/images/pricingproduct.jpg",
      route:'/pricingproduct',
    },
  ];

  const phone = userInfo?.phone;
  return (
    <div className="container-fluid">
      <div className="row">
        {services.map((service, index) => (
          <div key={index} className="col-md-3 mb-4">
            <div className="card" style={{ backgroundSize:"cover" }}>
              <img className="card-img" style={{borderRadius:"",objectFit:"cover"}} src={service.imageUrl} alt="pricingphotos"></img>
              <div className="card-body">
                <h5 className="card-title">{service.title1}</h5>
                {/* <h6 className="card-subtitle mb-2 text-muted">{service.discount}</h6> */}
                {/* {phone && (
                  <Link to={`/venuedate?data=${encodeURIComponent(service.route)}`}><button className="btn1">Checkout the Prices</button></Link>
                )} */}
                {/* {!phone &&( */}
                  <Link to={`/services/${service.title}`}><button className="btn1">See Details</button></Link>
                {/* )} */}
              </div>
            </div>
          </div>
        ))}
      </div>
      <Footer/>
    </div>
  );
};

export default Pricing;
