import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import './Footer.css';

const Footer = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const cities = [
    'Mumbai', 'Delhi', 'Bangalore', 'Hyderabad', 'Ahmedabad', 'Chennai', 'Kolkata', 'Surat', 'Pune', 'Jaipur',
    'Lucknow', 'Kanpur', 'Nagpur', 'Indore', 'Thane', 'Bhopal', 'Visakhapatnam', 'Pimpri-Chinchwad', 'Patna', 'Vadodara',
    'Ghaziabad', 'Ludhiana', 'Agra', 'Nashik', 'Faridabad', 'Meerut', 'Rajkot', 'Varanasi'
  ];

  const services = [
    { title1: "Pre Wedding", title: "Pre-Wedding-Photographers", route: '/pricingprewedding' },
    { title1: "Wedding", title: "Wedding-Photographers", route: '/pricingwedding' },
    { title1: "Haldi", title: "Haldi-Photographers", route: '/pricinghaldi' },
    { title1: "Mehandi", title: "Mehandi-Photographers", route: '/pricingmehandi' },
    { title1: "Sangeet", title: "Sangeet-Photographers", route: '/pricingsangeet' },
    { title1: "Engagement", title: "Engagement-Photographers", route: '/pricingengagement' },
    { title1: "Reception", title: "Reception-Photographers", route: '/pricingreception' },
    { title1: "Pre Birthday Party", title: "Pre-Birthday-Party-Photographers", route: '/pricingprebirthday' },
    { title1: "Birthday Shoot", title: "Birthday-Shoot-Photographers", route: '/pricingbirthday' },
    { title1: "New Born Baby", title: "New-Born-Baby-Photographers", route: '/pricingnewbornbaby' },
    { title1: "Baby Shoot", title: "Baby-Shoot-Photographers", route: '/pricingbaby' },
    { title1: "Maternity", title: "Maternity-Photographers", route: '/pricingmaternity' },
    { title1: "Wedding Anniversary", title: "Wedding-Anniversary-Photographers", route: '/pricinganniversary' },
    { title1: "Corporate Event", title: "Corporate-Event-Photographers", route: '/pricingcorporate' },
    { title1: "Portfolio", title: "Portfolio-Photographers", route: '/pricingportfolio' },
    { title1: "Product", title: "Product-Photographers", route: '/pricingproduct' },
  ];
  useEffect(() => {
    const counter = document.getElementById("counter");
    const counter1 = document.getElementById("counter1");
    const counter2 = document.getElementById("counter2");
    let count = 0;
    let count1 = 0;
    let count2 = 0;
    setInterval(() => {
      if (count < 1200) {
        count++;
        counter.innerText = count;
      }
    }, 1);
    setInterval(() => {
      if (count1 < 192) {
        count1+=10;
        counter1.innerText = count1;
      }
    }, 500);
    setInterval(() => {
      if (count2 < 5687457) {
        count2+=10000;
        counter2.innerText = count2;
      }
    },1);
  }, []);
  return (
    <footer className="footer">
      <div className="footerColumns">
    <div className="footerColumn">
      <img src="/logo.png" alt="Logo" className="footerLogo" />
      <p>Our Fotoshoot serves as a stepping stone in revolutionizing photography and videography within the digital world, offering an efficient and high-quality experience.</p>
    </div>
    <div className="footerColumn">
      <h3>Quick Links</h3>
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/aboutus">About Us</Link></li>
        <li><Link to="/tojoinus">Join Us</Link></li>
        <li><Link to="/contact">Contact</Link></li>
        <li><a href="/sitemap.xml" target="_blank" rel="noopener noreferrer">Sitemap</a></li>
      </ul>
    </div>
    <div className="footerColumn">
      <h3>Opening Hours</h3>
      <p>Monday - Friday: 9am to 6pm</p>
      <p>Saturday: 10am to 4pm</p>
      <p>Sunday: Closed</p>
    </div>
    <div className="footerColumn">
      <div className="counter">
        <h3 className="counter-heading">Numbers Don't Lie</h3>
        <div style={{display:"flex"}}>
          <div className="counter-value" id="counter">0</div>
          <div style={{fontSize:"16px"}}>+ shoots done</div>
        </div>
        <div style={{display:"flex"}}>
          <div className="counter-value" id="counter1">0</div>
          <div style={{fontSize:"16px"}}>+ Cities</div>
        </div>
        <div style={{display:"flex"}}>
          <div className="counter-value" id="counter2">7580000</div>
          <div style={{fontSize:"16px"}}>+ Images Processed</div>
        </div>
      </div>
    </div>
  </div>
  <div className="footerColumnsHome services-sectionHome">
  {services.map((service, index) => (
    <div key={index} className="serviceDivHome">
      <h3 className="serviceTitleHome">Best Photography for {service.title1}</h3>
      <ul className="cityListHome">
        {cities.map((city, idx) => (
          <li key={idx}>
            <Link onClick={handleScrollToTop} to={`/services/${service.title}/${city}`}>Best {service.title1} Photography in {city}</Link>
          </li>
        ))}
      </ul>
      <div className="footerLineHome"></div>
    </div>
  ))}
</div>
  <p className="footerText">© {new Date().getFullYear()} FotoShoot. All rights reserved.</p>
</footer>

  );
};

export default Footer;
