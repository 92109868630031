// TestimonialPage.js
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faQuoteLeft, faCamera } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import './TestimonialPage.css';
import Footer from './Footer';

const TestimonialPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    rating: '',
    review: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch('/testimonial', {
      method: 'POST',
      body: JSON.stringify({formData}),
      headers: {'Content-Type':'application/json'},
  });
    console.log('Form data submitted:', formData);
  };

  return (
    <div className="testimonial-page">
      <Helmet>
      <meta name="description" content="FotoShoot is an online platform that transforms your cherished memories into reality through captivating imagery"/>
      <link rel="canonical" href="https://fotoshoot.in/testimonials"/>
      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11016491595"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-11016491595');
          `}
        </script>
      </Helmet>
      <div className="recent-reviews">
        <div className="recent-review">
          <div className="review-header">
            <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon" />
            <p className="rating">
              5 <FontAwesomeIcon icon={faStar} className="star-icon" />
            </p>
          </div>
          <p className="review-text">
          Their team's skillful approach and attention to detail captured the essence of the moment effortlessly. 
          </p>
          <p className="review-author">- Rishabh</p>
        </div>
        <div className="recent-review">
          <div className="review-header">
            <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon" />
            <p className="rating">
              5 <FontAwesomeIcon icon={faStar} className="star-icon" />
            </p>
          </div>
          <p className="review-text">
            The final shots were amazing and we were very happy with the results.
          </p>
          <p className="review-author">- Anil kumar</p>
        </div>
        <div className="recent-review">
          <div className="review-header">
            <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon" />
            <p className="rating">
              5 <FontAwesomeIcon icon={faStar} className="star-icon" />
            </p>
          </div>
          <p className="review-text">
          From start to finish, their dedication to making us comfortable and delivering quality was evident.
          </p>
          <p className="review-author">- Amit Jain</p>
        </div>
        <div className="recent-review">
          <div className="review-header">
            <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon" />
            <p className="rating">
              5 <FontAwesomeIcon icon={faStar} className="star-icon" />
            </p>
          </div>
          <p className="review-text">
          I wholeheartedly recommend their photography for anyone seeking timeless memories with a touch of artistry.
          </p>
          <p className="review-author">- Devesh</p>
        </div>
      </div>
      <div className="feedback-form">
        <div className="photo-icon">
        <h2 style={{marginRight:"2px"}}>Leave a Review</h2>
        <FontAwesomeIcon icon={faCamera} />
      </div>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleInputChange}
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleInputChange}
          />
          <select
            name="rating"
            value={formData.rating}
            onChange={handleInputChange}
          >
            <option value="" disabled>
              Rate us (1-5)
            </option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
          <textarea
            name="review"
            placeholder="Write your review here"
            value={formData.review}
            onChange={handleInputChange}
          />
          <button className='formbut' type="submit">Submit Review</button>
        </form>
      </div>
      <Footer/>
    </div>
  );
};

export default TestimonialPage;
