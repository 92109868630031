import './ToBeFreelancer.css';
import {useState} from 'react';
import { Helmet } from 'react-helmet';
import React, { useRef } from "react";
import Footer from './Footer';
export default function ToBeFreelancer(){
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    skills: "",
    years:"",
    portfolioLink: "",
    ModelNumber:"",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('/joinus', {
      method: 'POST',
      body: JSON.stringify({formData}),
      headers: {'Content-Type':'application/json'},
    });
    console.log("Form submitted:", formData);
    setFormData({
      name: "",
      email: "",
      skills: "",
      years:"",
      portfolioLink: "",
      ModelNumber: "",
      message: "",
    });
  };

  const formRef = useRef();

  const handleApplyNowClick = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return(
    <div className='toBeFreelancerPage1'>
      <Helmet>
        <title>Apply to Be a Freelancer - Fotoshoot</title>
        <meta name="description" content="Join our team of talented photographers and showcase your work to the world. Access a wide range of photography projects, showcase your work to a larger audience, and more." />
        <meta name="robots" content="index, follow" />

        <meta property="og:title" content="To be freelancer" />
        <meta property="og:description" content="it's fotoshoot" />
        <meta property="og:image" content="freelancer.webp" />
        <meta property="og:url" content="https://fotoshoot.in/tojoinus" />
        <meta property="og:type" content="fotoshoot" />


        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@yourtwitterhandle" />
        <meta name="twitter:title" content="Your Page Title" />
        <meta name="twitter:description" content="Your page description." />
        <meta name="twitter:image" content="freelance.webp" />

        <meta name="author" content="Fotoshoot" />
        <meta name="language" content="en" />
        <meta name="keywords" content="keyword1, keyword2, keyword3" />
        <meta name="revisit-after" content="3 days" />

        <meta name="distribution" content="global" />
        <meta name="rating" content="General" />

        <meta name="category" content="Category Name" />
        <meta name="genre" content="Genre Name" />

        <meta name="google-site-verification" content="Your Verification Code" />
        <meta name="referrer" content="no-referrer" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11016491595"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-11016491595');
          `}
        </script>
      </Helmet>
      <div className='header1'>
        <div className="headerContent">
          <h1>Welcome to Fotoshoot's Photography Revolution</h1>
          <p>Join our team of talented photographers and showcase your work to the world.</p>
          <button onClick={handleApplyNowClick}>Apply Now</button>
        </div>
      </div>
        <div className="container">
        <div className="infoCard1">
          <h3 className="infoCardTitle">Collaborate with Us</h3>
          <ul className="bulletPoints">
            <li>Access to a wide range of photography projects</li>
            <li>Showcase your work to a larger audience</li>
            <li>Flexible working hours and project choices</li>
            <li>Networking opportunities with other photographers</li>
            <li>Receive fair compensation for your skills</li>
          </ul>
        </div>
          <form ref={formRef} className="form-container1" onSubmit={handleSubmit}>
            <h2 style={{textAlign:"center",fontFamily:"Montserrat, sans-serif"}}>Join Us!</h2>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="input-field"
              placeholder="Name"
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="input-field"
              placeholder="Email"
            />
            <input
              type="text"
              name="skills"
              value={formData.skills}
              onChange={handleChange}
              required
              className="input-field"
              placeholder="Skills:Candid,Cinematic,Traditional....."
            />
            <input
              type="text"
              name="years"
              value={formData.years}
              onChange={handleChange}
              required
              className="input-field"
              placeholder="Experience:Years"
            />
            <input
              type="url"
              name="portfolioLink"
              value={formData.portfolioLink}
              onChange={handleChange}
              required
              className="input-field"
              placeholder="Portfolio Link"
            />
            <input
              type="text"
              name="ModelNumber"
              value={formData.ModelNumber}
              onChange={handleChange}
              required
              className="input-field"
              placeholder="Camera(Model.Number and Serial.Number)"
            />
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              className="textarea-field"
              placeholder="Message(Maximum-300 Words)"
              maxLength={300}
            />
            <button type="submit" className="submit-button">
              Submit
            </button>
          </form>
        </div>
        <Footer/>
    </div>
  );
};
