import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import './FooterServicesMain.css';

const FooterServicesMain = ({ selectedService }) => {
  const cities = [
    "Bangalore", "Delhi", "Chennai", "Hyderabad", "Mumbai", "Pune", "Kolkata", "Ahmedabad",
    "Amritsar", "Bhopal", "Bhubaneswar", "Chandigarh", "Faridabad", "Ghaziabad", "Jamshedpur",
    "Jaipur", "Kochi", "Lucknow", "Nagpur", "Patna", "Raipur", "Surat", "Visakhapatnam", 
    "Agra", "Ajmer", "Mysuru", "Srinagar", "Etawah", "Roorkee", "Rajamundry", "Bhatinda",
    "Hajipur", "Rohtak", "Hosur", "Gandhinagar", "Junagadh", "Udaipur", "Salem", "Jhansi",
    "Madurai", "Vijaywada", "Meerut", "Mathura", "Bikaner", "Cuttack", "Nashik",
    "Ayodhya", "Bareilly", "Fatehpur-Sikri", "Unnao", "Hardoi", "Varanasi", "Barabanki", 
    "Noida", "Chitrakoot-Uttar-Pradesh", "Prayagraj", "Coimbatore", "Indore", 
    "Thiruvananthapuram", "Guwahati", "Vadodara", "Ranchi", "Ludhiana", 
    "Puducherry", "Jamnagar", "Warangal", "Kota", "Bhilai", "Kalyan-Dombivli", 
    "Vasai-Virar", "Aurangabad", "Dhanbad", "Amravati", "Allahabad", "Howrah", 
    "Rajkot", "Gwalior", "Jabalpur", "Bhilwara", "Haridwar", "Kakinada", "Belgaum", 
    "Jammu", "Ujjain", "Tirunelveli", "Malegaon", "Davangere", "Kozhikode", "Akola", 
    "Kurnool", "Mangalore", "Ambattur", "Tiruppur", "Moradabad", "Gorakhpur", 
    "Bhavnagar", "Dehradun", "Kollam", "Nanded", "Gulbarga", "Satna", "Chandrapur", 
    "Latur", "Durg", "Sangli-Miraj & Kupwad", "Hapur", "Bihar Sharif", "Panvel", 
    "Parbhani", "Ichalkaranji", "Tumkur", "Bathinda", "Rampur", "Shimoga", "Hisar", 
    "Fatehpur", "Shivamogga", "Chandannagar", "Hospet", "Hindupur", "Sikar", 
    "Bhagalpur", "Bellary", "Maheshtala", "Sultan Pur Majra", "Tirupati", "Bijapur", 
    "Raiganj", "Udupi", "Patiala", "Pathankot", "Betul", "Ramagundam", "Pali", 
    "Khammam", "Avadi", "Baranagar", "Dindigul", "Adoni", "Aizawl", "Bardhaman", 
    "Krishnanagar", "Yamunanagar", "Behrampur", "Bally", "Medininagar", "Munger", 
    "Ratlam", "Sambalpur", "Purnia", "Giridih", "Muzaffarpur", "Kottayam", "Kadapa", 
    "Karimnagar", "Bhimavaram", "Shimla", "Rewa", "New Delhi", "Gurgaon", 
    "Greater Noida", "Thane", "Virar", "Mira-Bhayandar", "Bhiwandi", "Pimpri-Chinchwad", 
    "Solapur", "Kolhapur", "Ichalkaranji", "Ahmednagar", "Satara", "Malegaon", "Dhule", 
    "Jalgaon", "Latur", "Nanded", "Parbhani", "Wardha", "Gondiya", "Bhusawal", 
    "Jalna", "Baramati", "Osmanabad", "Nandurbar", "Washim", "Hingoli", "Yavatmal", 
    "Gadchiroli", "Buldhana", "Pune", "Virar", "Mira-Bhayandar"
  ];

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const counter = document.getElementById("counter");
    const counter1 = document.getElementById("counter1");
    const counter2 = document.getElementById("counter2");
    let count = 0;
    let count1 = 0;
    let count2 = 0;
    setInterval(() => {
      if (count < 1200) {
        count++;
        counter.innerText = count;
      }
    }, 1);
    setInterval(() => {
      if (count1 < 192) {
        count1+=10;
        counter1.innerText = count1;
      }
    }, 500);
    setInterval(() => {
      if (count2 < 5687457) {
        count2+=10000;
        counter2.innerText = count2;
      }
    },1);
  }, []);
  return (
    <footer className="footerservicemain">
      <div className="footerColumns">
    <div className="footerColumn">
      <img src="/logo.png" alt="Logo" className="footerLogo" />
      <p>Our Fotoshoot serves as a stepping stone in revolutionizing photography and videography within the digital world, offering an efficient and high-quality experience.</p>
    </div>
    <div className="footerColumn">
      <h3>Quick Links</h3>
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/aboutus">About Us</Link></li>
        <li><Link to="/tojoinus">Join Us</Link></li>
        <li><Link to="/contact">Contact</Link></li>
        <li><a href="/sitemap.xml" target="_blank" rel="noopener noreferrer">Sitemap</a></li>
      </ul>
    </div>
    <div className="footerColumn">
      <h3>Opening Hours</h3>
      <p>Monday - Friday: 9am to 6pm</p>
      <p>Saturday: 10am to 4pm</p>
      <p>Sunday: Closed</p>
    </div>
    <div className="footerColumn">
      <div className="counter">
        <h3 className="counter-heading">Numbers Don't Lie</h3>
        <div style={{display:"flex"}}>
          <div className="counter-value" id="counter">0</div>
          <div style={{fontSize:"16px"}}>+ shoots done</div>
        </div>
        <div style={{display:"flex"}}>
          <div className="counter-value" id="counter1">0</div>
          <div style={{fontSize:"16px"}}>+ Cities</div>
        </div>
        <div style={{display:"flex"}}>
          <div className="counter-value" id="counter2">7580000</div>
          <div style={{fontSize:"16px"}}>+ Images Processed</div>
        </div>
      </div>
    </div>
  </div>
      <div className="footerColumnsMain services-section-Main">
        <div className="serviceDivMain">
          <h3 className="serviceTitleMain">Best {selectedService} Photography</h3>
          <ul className="cityListMain">
            {cities.map((city, idx) => (
              <li key={idx}>
                <Link 
                  to={`/services/${selectedService}-Photographers/${city}`} 
                  onClick={handleScrollToTop}
                >
                  Best {selectedService} Photography in {city}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
  <p className="footerText">© {new Date().getFullYear()} FotoShoot. All rights reserved.</p>
    </footer>
  );
};

export default FooterServicesMain;
