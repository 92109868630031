import './Portfolio.css';
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import ImageGallery from './ImageGallery';
import Slider from './FrontPage';
// import ThreedSlider from './ThreedSlider';
import Lottie from 'lottie-react';
import animationData from '../../src/assets/lottie2.json';
import lottie3 from '../../src/assets/lottie3.json';
import { Link } from 'react-router-dom';
// import Carousel from 'react-spring-3d-carousel';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import CoverflowCarousel from './Carousel';
import { Helmet } from 'react-helmet';
import Footer from './Footer';


const cities = [
  "Bangalore", "Delhi", "Chennai", "Hyderabad", "Mumbai", "Pune", "Kolkata", "Ahmedabad",
  "Amritsar", "Bhopal", "Bhubaneswar", "Chandigarh", "Faridabad", "Ghaziabad", "Jamshedpur",
  "Jaipur", "Kochi", "Lucknow", "Nagpur", "Patna", "Raipur", "Surat", "Visakhapatnam", 
  "Agra", "Ajmer", "Mysuru", "Srinagar", "Etawah", "Roorkee", "Rajamundry", "Bhatinda",
  "Hajipur", "Rohtak", "Hosur", "Gandhinagar", "Junagadh", "Udaipur", "Salem", "Jhansi",
  "Madurai", "Vijaywada", "Meerut", "Mathura", "Bikaner", "Cuttack", "Nashik",
  "Ayodhya", "Bareilly", "Fatehpur-Sikri", "Unnao", "Hardoi", "Varanasi", "Barabanki", 
  "Noida", "Chitrakoot-Uttar-Pradesh", "Prayagraj", "Coimbatore", "Indore", 
  "Thiruvananthapuram", "Guwahati", "Vadodara", "Ranchi", "Ludhiana", 
  "Puducherry", "Jamnagar", "Warangal", "Kota", "Bhilai", "Kalyan-Dombivli", 
  "Vasai-Virar", "Aurangabad", "Dhanbad", "Amravati", "Allahabad", "Howrah", 
  "Rajkot", "Gwalior", "Jabalpur", "Bhilwara", "Haridwar", "Kakinada", "Belgaum", 
  "Jammu", "Ujjain", "Tirunelveli", "Malegaon", "Davangere", "Kozhikode", "Akola", 
  "Kurnool", "Mangalore", "Ambattur", "Tiruppur", "Moradabad", "Gorakhpur", 
  "Bhavnagar", "Dehradun", "Kollam", "Nanded", "Gulbarga", "Satna", "Chandrapur", 
  "Latur", "Durg", "Sangli-Miraj & Kupwad", "Hapur", "Bihar Sharif", "Panvel", 
  "Parbhani", "Ichalkaranji", "Tumkur", "Bathinda", "Rampur", "Shimoga", "Hisar", 
  "Fatehpur", "Shivamogga", "Chandannagar", "Hospet", "Hindupur", "Sikar", 
  "Bhagalpur", "Bellary", "Maheshtala", "Sultan Pur Majra", "Tirupati", "Bijapur", 
  "Raiganj", "Udupi", "Patiala", "Pathankot", "Betul", "Ramagundam", "Pali", 
  "Khammam", "Avadi", "Baranagar", "Dindigul", "Adoni", "Aizawl", "Bardhaman", 
  "Krishnanagar", "Yamunanagar", "Behrampur", "Bally", "Medininagar", "Munger", 
  "Ratlam", "Sambalpur", "Purnia", "Giridih", "Muzaffarpur", "Kottayam", "Kadapa", 
  "Karimnagar", "Bhimavaram", "Shimla", "Rewa", "New Delhi", "Gurgaon", 
  "Greater Noida", "Thane", "Virar", "Mira-Bhayandar", "Bhiwandi", "Pimpri-Chinchwad", 
  "Solapur", "Kolhapur", "Ichalkaranji", "Ahmednagar", "Satara", "Malegaon", "Dhule", 
  "Jalgaon", "Latur", "Nanded", "Parbhani", "Wardha", "Gondiya", "Bhusawal", 
  "Jalna", "Baramati", "Osmanabad", "Nandurbar", "Washim", "Hingoli", "Yavatmal", 
  "Gadchiroli", "Buldhana", "Pune", "Virar", "Mira-Bhayandar"
];


function Portfolio() {
  const [images, setImages] = useState([]);
  const [city, setCity] = useState("");
  const [errorMessage, setErrorMessage] = useState('');
  const [occasion, setOccasion] = useState("");
  const navigate = useNavigate();


  const occasions = [
    "Pre-Wedding-Photographers",
    "Wedding-Photographers",
    "Reception-Photographers",
    "Birthday-Shoot-Photographers",
    "Engagement-Photographers",
    "Corporate-Event-Photographers",
    "Haldi-Photographers",
    "Sangeet-Photographers",
    "Mehandi-Photographers",
    "Pre-Birthday-Party-Photographers",
    "New-Born-Baby-Photographers",
    "Baby-Shoot-Photographers",
    "Wedding-Anniversary-Photographers",
    "Portfolio-Photographers",
    "Product-Photographers",
    "Maternity-Photographers"
  ]

  const [selectedCity, setSelectedCity] = useState('');
  const [filteredCities, setFilteredCities] = useState([]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSelectedCity(value);
    setFilteredCities(getFilteredCities(value));
    setErrorMessage('');
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (selectedCity) {
  //     navigate(`/services/${serviceName}/${selectedCity}`);
  //   }
  // };

  const getFilteredCities = (value) => {
    const inputValue = value.trim().toLowerCase();
    if (inputValue.length === 0) {
      return [];
    }

    const startsWithInput = cities.filter(city =>
      city.toLowerCase().startsWith(inputValue)
    );

    const includesInput = cities.filter(city =>
      city.toLowerCase().includes(inputValue) && !city.toLowerCase().startsWith(inputValue)
    );

    return [...startsWithInput, ...includesInput];
  };

  const handleCityClick = (city) => {
    setSelectedCity(city);
    setFilteredCities([]);
  };

  const [slides, setSlides] = useState([
    {
      key: uuidv4(),
      content: <img src="freelance.webp" alt="Image 1" />,
    },
    {
      key: uuidv4(),
      content: <img src="/images/pricingbirthday.jpg" alt="Image 2" />,
    },
    {
      key: uuidv4(),
      content: <img src="freelance.webp" alt="Image 3" />,
    },
    {
      key: uuidv4(),
      content: <img src="freelance.webp" alt="Image 4" />,
    },
    {
      key: uuidv4(),
      content: <img src="freelance.webp" alt="Image 5" />,
    },
    {
      key: uuidv4(),
      content: <img src="freelance.webp" alt="Image 6" />,
    },
    {
      key: uuidv4(),
      content: <img src="freelance.webp" alt="Image 7" />,
    },
    {
      key: uuidv4(),
      content: <img src="freelance.webp" alt="Image 8" />,
    }
  ]);

  
  useEffect(() => {
    const interval = setInterval(() => {
      shuffleSlides();
    }, 2000); // Change the time interval (in milliseconds) as needed

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []); // Run only once on component mount

  const shuffleSlides = () => {
    const shuffledSlides = [...slides].sort(() => Math.random() - 0.8);
    setSlides(shuffledSlides);
  };

  const AboutUs = () => {
    return (
          <div className="about-us-container1">
            <h2 className="section-heading">ABOUT US</h2>
            <div className="about-us-content">
              <div className="about-us-text">
                  <h3>Welcome to Our World of Photography</h3>
                  <p>
                    At Fotoshoot we believe in the power of capturing moments and turning them into everlasting memories. Our journey began with a passion for photography, and over the years, we've honed our skills to freeze those special moments in time.
                  </p>
                  <p>
                    Explore our portfolio and let our work speak for itself. Join us on this visual journey, where every frame is a testament to the beauty of life's precious moments.
                  </p>
                  <Link to={'/aboutus'}><button className='custom-btn'>READ MORE</button></Link>
              </div>
            <div className="about-us-image">
            <img alt='gif' src='./Desinen copyWITH DITALAS .webp'/>
          </div>
        </div>
      </div>
    );
  };
  const Testimonials = () => {
    const testimonials = [
      {
        id: 1,
        name: "Abhay Bansal",
        quote: "Stunning wedding photos, exceptional family portraits, and professional headshots—highly recommended for quality photography services!",
        rating: 3.9,
        photo: "freelance.webp" // URL of the photo for the first client
      },
      {
        id: 2,
        name: "Rishabh Dabas",
        quote: "Impressive professionalism, prompt service, and captivating photos—exceeded expectations in every way possible!",
        rating: 5,
        photo: "./images/rishabh.jpg" // URL of the photo for the second client
      },
      {
        id: 3,
        name: "Ashish Mishra",
        quote: "The photography experience was amazing. They captured every moment beautifully!",
        rating: 4.9,
        photo: "freelance.webp" // URL of the photo for the third client
      },
      {
        id: 4,
        name: "Devesh Mishra",
        quote: "Incredible photography! Their attention to detail is unmatched.",
        rating: 5,
        photo: "./images/devesh.jpg" // URL of the photo for the fourth client
      },
    ];
  
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const testimonialsToDisplay = windowWidth >= 1075 && windowWidth <= 1275 ? testimonials.slice(0, 3) : testimonials;

  const handleClick = () => {
    const link = document.querySelector('.write-review-link');
    if (link) {
      link.classList.add('clicked');

      setTimeout(() => {
        window.location.href = '/testimonials';
      }, 500);
    }
  };

  return (
    <div className="testimonial-container">
      <h2 className="section-heading">LATEST REVIEWS</h2>
      <div className="testimonial-list">
        {testimonialsToDisplay.map((testimonial) => (
          <div
            key={testimonial.id}
            className="testimonial-item"
          >
            <div className="testimonial-info">
              <img
                src={testimonial.photo}
                alt={testimonial.name}
                className="client-photo"
              />
              <div className="testimonial-content">
                <p className="testimonial-text">"{testimonial.quote}"</p>
                <p className="testimonial-author">- {testimonial.name}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="centered-button">
        <Link
          to="#"
          className={`write-review-link`}
          onClick={handleClick}
        >
          Write a Review
        </Link>
      </div>
    </div>
  );
};
  const handleSubmit = (e) => {
    e.preventDefault();
    if (cities.map(city => city.toLowerCase()).includes(selectedCity.toLowerCase())) {
      navigate(`/services/${occasion}/${selectedCity}`);
    } else {
      setErrorMessage('Please enter a correct city.');
    }
};
const FAQs = () => {
  const faqs = [
    {
      question: "What makes FOTOSHOOT.IN stand out in wedding photography?",
      answer: "At FOTOSHOOT.IN, we specialize in capturing the essence and beauty of your wedding day with our expert team and creative approach."
    },
    {
      question: "How can I book a PreWedding photoshoot with FOTOSHOOT.IN?",
      answer: "Booking a PreWedding photoshoot with us is easy! Simply reach out to our team through our website or contact details provided."
    },
    {
      question: "Does FOTOSHOOT.IN offer birthday photography services?",
      answer: "Yes, we do! Celebrate your special day with us and let our photographers capture every joyous moment."
    },
    {
      question: "What can I expect from FOTOSHOOT.IN’s engagement photography services?",
      answer: "With FOTOSHOOT.IN, expect stunning photographs that beautifully capture the love and excitement of your engagement."
    },
    {
      question: "Do you provide ring ceremony photography services?",
      answer: "Absolutely! Our skilled photographers will ensure that every precious moment of your ring ceremony is captured in exquisite detail."
    },
    {
      question: "Can I trust FOTOSHOOT.IN for my reception photography needs?",
      answer: "Yes, you can trust us to document your reception with professionalism and creativity, preserving memories to cherish for a lifetime."
    },
    {
      question: "What sets FOTOSHOOT.IN apart in the field of wedding photography?",
      answer: "Our dedication to capturing the unique story of each couple, combined with our exceptional skills and attention to detail, makes us a standout choice for wedding photography."
    },
    {
      question: "How long does a PreWedding photoshoot typically last with FOTOSHOOT.IN?",
      answer: "PreWedding photoshoot durations vary based on the package chosen, usually ranging from 2 to 4 hours to ensure ample time for capturing beautiful moments."
    },
    {
      question: "Can I request specific themes or locations for my birthday photography session?",
      answer: "Absolutely! We welcome your ideas and preferences and will work with you to create a personalized birthday photography experience."
    },
    {
      question: "What post-production services does FOTOSHOOT.IN offer for engagement photography?",
      answer: "Our post-production services include professional editing to enhance the beauty of your engagement photographs and ensure they reflect your unique love story perfectly."
    },
  ];

  return (
    <div className="faq-container">
      <h2 className="faq-heading">FAQs</h2>
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <p className="faq-question"><span>{index + 1}. Q:</span> {faq.question}</p>
          <p className="faq-answer">{faq.answer}</p>
        </div>
      ))}
    </div>
  );
};

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};
  return (
    <div className='frontpage'>
      <Helmet>
      <meta name="description" content="FotoShoot is an online platform that transforms your cherished memories into reality through captivating imagery"/>
      <link rel="canonical" href="https://fotoshoot.in/"/>
      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11016491595"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-11016491595');
          `}
        </script>
      </Helmet>
      <div className="content-container">
          <Slider />
      </div>
      <div className='custom-container'>
      
      <div className='custom-half'>
      <h2 className="custom-heading">Find Nearby Services:</h2>
      <p className="custom-subheading">Explore unmatched services nearby! Discover exclusive offerings and exceptional deals. Don’t miss out – find nearby services today and enjoy great savings!</p>
      <form className="custom-form" onSubmit={handleSubmit}>
        <select
          className="custom-select"
          value={occasion}
          onChange={(e) => setOccasion(e.target.value)}
          required
        >
          <option value="" disabled>
            Choose Your Occasion
          </option>
          {occasions.map((occasion) => (
            <option key={occasion} value={occasion}>
              {occasion}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="Select your city"
          value={selectedCity}
          onChange={handleInputChange}
          className="custom-select"
        />
        {filteredCities.length > 0 && (
          <div className="suggestions-dialogd">
            {filteredCities.map(city => (
              <div
                key={city}
                className="suggestion-item"
                onClick={() => handleCityClick(city)}
              >
                {city}
              </div>
            ))}
          </div>
        )}
        <button type="submit" className="custom-btn">SEARCH</button>
      </form>
    </div>
      </div>
        <div className='custom-content1'>
          <p className="custom-subcontent1">Experience timeless elegance and captivating imagery with our professional photoshoot website. Our expert photographers bring your vision to life with precision, crafting stunning visuals that narrate your unique story. Whether it’s enchanting portraits or documenting memorable events, we specialize in preserving every emotion and detail. From tender engagement moments to jubilant wedding celebrations, we ensure utmost care and professionalism in every shot. With a keen eye for detail and storytelling, each photograph captures your journey’s essence, allowing cherished memories to last a lifetime. Trust us to transform your ideas into captivating realities, reflecting the beauty and significance of your moments.</p>
        </div>
      <div className='threed'>
      <h2 style={{fontFamily:"Montserrat Alternates", fontSize: '32px',textAlign:"center",marginBottom:"40px" ,paddingTop:"25px", '@media screen and (max-width: 768px)': { fontSize: '16px' } }}>
        Wedding Gallery
      </h2>
      <CoverflowCarousel/>
      </div>
      <div className='content-container1'>
        <div className='lottie-animation'>
          <h1 style={{fontFamily:"Montserrat Alternates"}}>Authentic,<br/>
          Aesthetic, <br/>Lifestyle Photography</h1>
        </div>
        <div className='content-container1-p' style={{alignItems:'center'}} >
          <p><span style={{display:"block",marginBottom:"10px"}}> <strong>FOTOSHOOT</strong>-  Framing Our Timeless Occasions, Striving to Highlight Our One-of-a-kind Talents,<br/></span><span style={{display:"block",marginBottom:"10px"}}> <strong>FOTOSHOOT</strong> can also represent “Flawlessly Orchestrated Tales Of Splendid Happenings, Offering Outstanding Transformations.”<br/></span><span style={{display:"block",marginBottom:"10px"}}><strong>FOTOSHOOT</strong> represents “Forever Capturing Moments Of Timeless Occasions, Skillfully Highlighting Our Talents.”</span></p>
          <Link to='/services'><button className='custom-btn'>SERVICES</button></Link>
        </div>
      </div>
      <AboutUs/>
      <Testimonials/>
    <FAQs/>
    <Footer/>
    </div>
  );
}

export default Portfolio;
